











































@import '@design';

.menu {
  width: 100%;
  max-width: 1440px;
}
